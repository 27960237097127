import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

class Modal extends Component {
  static propTypes = {
    title: PropTypes.string,
    styles: PropTypes.object,
    content: PropTypes.any,
    onCloseClick: PropTypes.func
  };
  render() {
    const styles = require('./Modal.less');
    return (
      <Fragment>
        <div className={styles.modal} style={this.props.styles}>
          <div className={styles.inner}>
            <span className={styles.close} onClick={this.props.onCloseClick} />
            <h2>{this.props.title}</h2>
            {
              this.props.content
            }
          </div>
        </div>
        <div className={styles.shadow} />
      </Fragment>
    )
  }
}

export default Modal;