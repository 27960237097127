import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Element, Link as LinkSlider } from 'react-scroll';

class Tarif extends Component {
  render() {
    const styles = require('./Tarif.less');
    return (
      <Element name="link4" className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>Тарифы</h2>
            <ul className={ styles.tarifList }>
              <li className={ styles.basic }>
                <div className={ styles.header }>Basic</div>
                <div className={ styles.content }>
                  <ul>
                    <li>$99 <span>/ год</span></li>
                    <li><span>до</span> 100 <span>отзывов</span></li>
                  </ul>
                  <div className={ styles.control }>
                    <Link to="/login?subId=register">Начать</Link>
                    <span className={ styles.arrowDown } />
                    <Link to="/tariffs" className={ styles.button }>Узнать больше</Link>
                  </div>
                </div>
              </li>
              <li className={ styles.standard }>
                <div className={ styles.header }>Standard</div>
                <div className={ styles.content }>
                  <ul>
                    <li>$249 <span>/ год</span></li>
                    <li><span>до</span> 250 <span>отзывов</span></li>
                  </ul>
                  <div className={ styles.control }>
                    <Link to="/login?subId=register">Начать</Link>
                    <span className={ styles.arrowDown } />
                    <Link to="/tariffs" className={ styles.button }>Узнать больше</Link>
                  </div>
                </div>
              </li>
              <li className={ styles.premium }>
                <div className={ styles.header }>Premium</div>
                <div className={ styles.content }>
                  <ul>
                    <li>$599 <span>/ год</span></li>
                    <li><span>до</span> 600 <span>отзывов</span></li>
                  </ul>
                  <div className={ styles.control }>
                    <Link to="/login?subId=register">Начать</Link>
                    <span className={ styles.arrowDown } />
                    <Link to="/tariffs" className={ styles.button }>Узнать больше</Link>
                  </div>
                </div>
              </li>
              <li className={ styles.elite }>
                <div className={ styles.header }>Elite</div>
                <div className={ styles.content }>
                  <ul>
                    <li>$1199 <span>/ год</span></li>
                    <li><span>до</span> 1200 <span>отзывов</span></li>
                  </ul>
                  <div className={ styles.control }>
                    <Link to="/login?subId=register">Начать</Link>
                    <span className={ styles.arrowDown } />
                    <Link to="/tariffs" className={ styles.button }>Узнать больше</Link>
                  </div>
                </div>
              </li>
              <li className={ styles.enterprise }>
                <div className={ styles.header }>Enterprise</div>
                <div className={ styles.content }>
                  <ul>
                    <li><span>Свяжитесь с нами и мы дадим вам самую привлекательную цену</span></li>
                    <li><span>от</span> 1200 <span>отзывов</span></li>
                  </ul>
                  <div className={ styles.control }>
                    <Link to="/login?subId=register">Начать</Link>
                    <span className={ styles.arrowDown } />
                    <Link to="/tariffs" className={ styles.button }>Узнать больше</Link>
                  </div>
                </div>
              </li>
            </ul>
            <p>Если у вас особый случай, вы можете <LinkSlider to="link6" spy={true} smooth={true} offset={-50} duration={500}>обратиться к нам</LinkSlider>, и мы адаптируем MVoter под ваши уникальные задачи</p>
          </div>
        </div>
      </Element>
    )
  }
}

export default Tarif;
