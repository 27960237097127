const environment = {
  development: {
    isProduction: false,
    assetsPath: `http://${process.env.HOST || 'localhost'}:${+process.env.PORT + 1 || 3001}/dist/`
  },
  production: {
    isProduction: true,
    assetsPath: '/dist/'
  }
}[process.env.NODE_ENV || 'development'];

const config = {
  protocol: 'http',
  host: process.env.HOST || 'mvoter.local',
  port: process.env.PORT,
  apiHost: process.env.APIHOST || 'backend',//'cabinet.mvoter.com',//'backend',
  apiPort: process.env.APIPORT || 8000,//400,//8000,
  app: {
    title: 'Mvoter.com',
    description: 'Mvoter.com',
    head: {
      titleTemplate: '%s',
      meta: []
    }
  },
  i18n: {
    en: {
      previousMonth: 'Previous Month',
      nextMonth: 'Next Month',
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      months2: ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'],
      shortMonths: ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec'],
      weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      weekdaysShort: ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa']
    },
    ru: {
      previousMonth: 'Предыдущий месяц',
      nextMonth: 'Следующий месяц',
      months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
      months2: ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'],
      shortMonths: ['янв', 'фев', 'мар', 'апр', 'май', 'июн', 'июл', 'авг', 'сен', 'окт', 'ноя', 'дек'],
      weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
      weekdaysShort: ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    }
  }
};

Object.assign(config, environment);

export default config;
