import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { feedback } from 'redux/modules/auth';
import { required, email } from '../../utils/validation';

class JoinModal2 extends Component {
    static propTypes = {
        feedback: PropTypes.func
    };
    state = {
        successText: false,
        errorName: false,
        errorPhone: false,
        form: {
            firstname: '',
            phone: ''
        }
    }
    onChangeForm = (id, value) => {
        const newState = this.state;
        newState.form[id] = value;
        this.setState(newState);
    }
    modalBtnClick = () => {
        if (this.state.form.firstname == '' || required(this.state.form.firstname)) {
            this.setState({ errorName: true });
            return false;
        }
        if (this.state.form.phone == '' || required(this.state.form.phone)) {
            this.setState({ errorPhone: true, errorName: false });
            return false;
        }
        const newState = this.state;
        newState.successText = true;
        newState.errorName = false;
        newState.errorPhone = false;
        this.setState(newState);
        this.props.feedback({ ...this.state.form, id: 'app:feedback' });
        setTimeout(() => this.setState({ successText: false }, () => this.props.onCloseClick()), 3000);
    }
    render() {
        const styles = require('./JoinModal.less');

        return (
            <form>
                <div className={ styles.inputWrap }>
                    <input onChange={ e => this.onChangeForm('firstname', e.target.value) } type="text" placeholder="Имя *" className={ this.state.errorName ? styles.error : '' } />
                </div>
                <div className={ styles.inputWrap }>
                    <input onChange={ e => this.onChangeForm('phone', e.target.value) } type="text" placeholder="Номер телефона *" className={ this.state.errorPhone ? styles.error : '' } />
                </div>
                <p>Указывая свои данные вы даёте согласие на обработку персональных данных, в соответствии с политикой конфиденциальности</p>
                { this.state.successText ? 
                    <div className={ styles.row }>
                        <p className={ styles.successText }>Ваши данные успешно отправлены</p>
                    </div>
                    : null
                }
                <span className={ styles.button } onClick={ () => this.modalBtnClick() }>Отправить</span>
            </form>
        )
    }
}

export default connect(
    () => ({}),
    { feedback }
  )(JoinModal2);