import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { init } from 'ityped';
import { Sticky } from 'react-sticky';
import { Link as LinkSlider } from 'react-scroll';
import Join from '../../components/JoinModal/JoinModal';
import Join2 from '../../components/JoinModal/JoinModal2';
import Modal from '../../components/Modal/Modal';
import '../../theme/TypingText.css';
import "./slick.min.css";
import "./slick-theme.min.css";
import Slider from "react-slick";

class Header extends Component {
  static propTypes = {
    location: PropTypes.objectOf(PropTypes.any)
  };
  state = {
    modal: false,
    modal2: false,
    mobileMenu: false,
    titleWidth: '140px',
  };
  componentDidMount() {
    // this.changeText1();
    // this.changeText2();
    if (this.props.location.search === '?subId=demo') {
      this.setState({ modal2: true });
    }
  }
  changeText1 = () => {
    let el1 = document.querySelector('#typingText1');
    init(el1, {
      typeSpeed: 80,
      backSpeed: 0,
      startDelay: 500,
      backDelay: 2530,
      showCursor: false,
      loop: false,
      strings: ['удовлетворенность', 'лояльность       ', 'продажи          '],
      onFinished: () => {
        setTimeout(() => {
          let el1 = document.querySelector('#typingText1');
          let el2 = document.querySelector('#typingText2');
          if (el1) {
            el1.textContent = '';
            this.changeText1();
          }
          if (el2) {
            el2.textContent = '';
            this.changeText2();
          }
        }, 3500);
      }
    });
  };
  changeText2 = () => {
    const el2 = document.querySelector('#typingText2');
    init(el2, {
      typeSpeed: 80,
      backSpeed: 0,
      startDelay: 1800,
      backDelay: 1400,
      showCursor: false,
      loop: false,
      strings: ['ваших клиентов ', 'ваших клиентов ', 'вашим клиентам!']
    });
  };
  render() {
    const styles = require('./Header.less');
    const main1 = require('../../../static/images/main1.svg');
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };

    return (
      <header className={styles.header}>
        <div className={styles.inner}>
          <div className={styles.wrap}>
            <div className={styles.topSide}>
              <Link to="/" className={styles.logo} />
              <ul className={`${styles.menu} ${this.state.mobileMenu ? styles.mobmenu : ''}`}>
                <li><LinkSlider to="link2" spy={true} smooth={true} offset={-50} duration={500}>О системе</LinkSlider></li>
                <li><LinkSlider to="link1" spy={true} smooth={true} offset={-50} duration={500}>Отрасли</LinkSlider></li>
                <li><LinkSlider to="link3" spy={true} smooth={true} offset={-50} duration={500}>Аналитика</LinkSlider></li>
                <li><LinkSlider to="link4" spy={true} smooth={true} offset={-50} duration={500}>Тарифы</LinkSlider></li>
                <li><LinkSlider to="link5" spy={true} smooth={true} offset={-50} duration={500}>Поддержка</LinkSlider></li>
                <li><LinkSlider to="link6" spy={true} smooth={true} offset={-50} duration={500}>Контакты</LinkSlider></li>
                <li><Link to="/login">Войти</Link></li>
                <li><Link to="/login?subId=register" className={styles.register}>Регистрация</Link></li>
              </ul>
              <span className={`${styles.burger} ${this.state.mobileMenu ? styles.close : ''}`} onClick={() => this.setState({ mobileMenu: !this.state.mobileMenu })} />
            </div>
            <div className={styles.bottomSide}>
              <div className={styles.leftSide}>
                <h1>Онлайн сервис для сбора и
                  анализа отзывов и жалоб ваших Клиентов,
                  потребителей товаров и услуг</h1>
                <img src={main1} className={styles.mainImgMob} style={{ display: 'none' }} width="530" height="530" alt="Иллюстрация, демонстрирующая как работает Mvoter" />
                <h2>Платформа для создания моментальной</h2>
                <h2 style={{ marginTop: '6px' }}>обратной связи с Целевой Аудиторией</h2>
                {/* <h2 style={{ marginTop: '6px' }}></h2> */}
                {/* <h2 style={{ marginTop: '10px' }}>
                  <span id="typingText1" className={ styles.fillTitle } />
                </h2>
                <h2 style={{ marginTop: '20px' }}>
                  <span id="typingText2" className={ styles.fillTitle } />
                </h2> */}
                <h2 className={styles.m420} style={{ display: 'none' }}>Платформа для создания моментальной
                  обратной связи с Целевой Аудиторией</h2>
                <div className={styles.slider}>
                  <Slider {...settings}>
                    <div className={styles.item}>
                      <p>Провести онлайн опрос потребителей товаров и услуг и собрать оценки, отзывы и аналитику для управления удовлетворенностью Клиентов</p>
                    </div>
                    <div className={styles.item}>
                      <p>Создать онлайн-анкету опросник для сбора и анализа обратной связи от Клиентов</p>
                    </div>
                    {/* <div className={ styles.item }>
                      <p> Цены и тарифные планы на услуги онлайн платформы по сбору отзывов и управлению удовлетворенностью Клиентов Mvoter</p>
                    </div> */}
                  </Slider>
                </div>
                <ul className={styles.buttonList}>
                  <li><Link to="/login?subId=register">Зарегистрироваться</Link></li>
                  <li><Link to="/" onClick={() => this.setState({ modal: !this.state.modal })}>Запросить демонстрацию</Link></li>
                </ul>
              </div>
              <div className={styles.rightSide}>
                <img src={main1} width="530" height="530" alt="Иллюстрация, демонстрирующая как работает Mvoter" />
                <ul className={styles.buttonList}>
                  <li style={{ display: 'none' }}><Link to="/login?subId=register">Начать бесплатно</Link></li>
                  <li><Link to="/" onClick={() => this.setState({ modal: !this.state.modal })}>Запросить демонстрацию</Link></li>
                </ul>
              </div>
            </div>
            <LinkSlider to="link1" spy={true} smooth={true} offset={0} duration={500} className={styles.arrowDown} />
            <Sticky topOffset={120}>
              {({ isSticky }) => (
                <div style={isSticky ? {} : { display: 'none' }} className={styles.sticky}>
                  <div className={styles.inner}>
                    <div className={styles.wrap}>
                      <div className={styles.topSide}>
                        <Link to="/" className={styles.logo} />
                        <ul className={`${styles.menu} ${this.state.mobileMenu ? styles.mobmenu : ''}`}>
                          <li><LinkSlider to="link2" spy={true} smooth={true} offset={-50} duration={500}>О системе</LinkSlider></li>
                          <li><LinkSlider to="link1" spy={true} smooth={true} offset={-50} duration={500}>Отрасли</LinkSlider></li>
                          <li><LinkSlider to="link3" spy={true} smooth={true} offset={-50} duration={500}>Аналитика</LinkSlider></li>
                          <li><LinkSlider to="link4" spy={true} smooth={true} offset={-50} duration={500}>Тарифы</LinkSlider></li>
                          <li><LinkSlider to="link5" spy={true} smooth={true} offset={-50} duration={500}>Поддержка</LinkSlider></li>
                          <li><LinkSlider to="link6" spy={true} smooth={true} offset={-50} duration={500}>Контакты</LinkSlider></li>
                          <li><Link to="/login">Войти</Link></li>
                          <li><Link to="/login?subId=register" className={styles.register}>Регистрация</Link></li>
                        </ul>
                        <span className={`${styles.burger} ${this.state.mobileMenu ? styles.close : ''}`} onClick={() => { this.setState({ mobileMenu: !this.state.mobileMenu }) }} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </Sticky>
          </div>
        </div>
        {this.state.modal &&
          <Modal
            title="Запросить демонстрацию"
            content={<Join onCloseClick={() => this.setState({ modal: false })} />}
            onCloseClick={() => this.setState({ modal: false })}
          />
        }
        {this.state.modal2 &&
          <Modal
            title="Введите данные"
            content={<Join2 onCloseClick={() => this.setState({ modal2: false })} />}
            onCloseClick={() => this.setState({ modal2: false })}
          />
        }
      </header>
    )
  }
}

export default Header;
