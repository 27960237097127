import React from 'react';
import Loadable from 'react-loadable';

const Report = Loadable({
  loader: () => import('./Report' /* webpackChunkName: 'report' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Analytic = Loadable({
  loader: () => import('./Analytic' /* webpackChunkName: 'analytic' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const CallCentre = Loadable({
  loader: () => import('./CallCentre' /* webpackChunkName: 'callCentre' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Setting = Loadable({
  loader: () => import('./Setting' /* webpackChunkName: 'setting' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Subject = Loadable({
  loader: () => import('./Subject' /* webpackChunkName: 'subject' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Branch = Loadable({
  loader: () => import('./Branch' /* webpackChunkName: 'branch' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Create = Loadable({
  loader: () => import('./Create' /* webpackChunkName: 'create' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Interview = Loadable({
  loader: () => import('./Interview' /* webpackChunkName: 'interview' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Survey = Loadable({
  loader: () => import('./Survey' /* webpackChunkName: 'survey' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const SuperQr = Loadable({
  loader: () => import('./SuperQr' /* webpackChunkName: 'superqr' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

const Design = Loadable({
  loader: () => import('./Design' /* webpackChunkName: 'design' */).then(module => module.default),
  loading: () => <div>Loading</div>
});

export { Report, Subject, Branch, Setting, CallCentre, Analytic, Create, Survey, Interview, SuperQr, Design };
