import React, { Component } from 'react';
import { Element } from 'react-scroll';

class Faq extends Component {
  state = {
    item: ''
  };
  render() {
    const styles = require('./Faq.less');
    return (
      <Element name="link5" className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>Часто задаваемые вопросы (FAQ)</h2>
            <ul className={ styles.faqList }>
              <li className={ this.state.item === 'one' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'one' ? 'none' : 'one' }) }>
                <div className={ styles.header }>Сколько времени нужно, чтобы все настроить?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'one' ? 'block' : 'none' }}>Время настройки зависит от масштабов и уровня детализации данных которые вы хотите собирать в рамках системы. Если вы малый бизнес (1 магазин, 1 ресторан, 1 кондитерская) то настройка системы обратной связи займет от 20-30 минут до 1-1,5 часа. Если вы средний бизнес (сеть из 5-10 магазинов/ресторанов, производство товаров до 30-50 SKU, и т.д.) то настройка системы займет от 1,5 часа до 3-4 часов. Если же вы представляете крупный и разветвленный бизнес или государственную организацию с десятками тысяч клиентов, то настройка системы займет от 1-2 дней до 5-7 дней.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'two' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'two' ? 'none' : 'two' }) }>
                <div className={ styles.header }>У нас уже установлены QR коды на многих объектах, надо ли их менять?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'two' ? 'block' : 'none' }}>Если у вас уже установлены QR коды, то вам не обязательно их менять. Достаточно интегрировать их с системой MVoter. Для этого в MVoter имеются удобные инструменты.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'three' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'three' ? 'none' : 'three' }) }>
                <div className={ styles.header }>Нужно ли нам дополнительное оборудование для использования MVoter?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'three' ? 'block' : 'none' }}>Никакого дополнительного оборудования не надо. Вам необходимо сперва настроить систему обратной связи на портале MVoter а затем распечатать на принтере QR коды (информеры, таблички, бейджики) и повесить их на самые видные места.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'four' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'four' ? 'none' : 'four' }) }>
                <div className={ styles.header }>У меня очень маленький бизнес, подходит ли MVoter для меня?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'four' ? 'block' : 'none' }}>Система MVoter подходит даже для самого маленького микробизнеса, будь это индивидуальный таксист, розничная торговая лавка, или мелкий производитель продуктов питания. MVoter позволит вам автоматизировать сбор отзывов и повысить лояльность ваших клиентов.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'five' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'five' ? 'none' : 'five' }) }>
                <div className={ styles.header }>У нас огромный бизнес с десятками региональных филиалов, с сотнями точек обслуживания, с тысячами обслуживающего персонала. Подходит ли MVoter для нас?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'five' ? 'block' : 'none' }}>MVoter легко адаптируется под самый сложный и разветвленный бизнес, вне зависимости от размеров Целевой Аудитории, географии и ассортимента ваших услуг и товаров. MVoter сможет в автоматическом режиме собирать все отзывы ваших клиентов, обрабатывать и выдавать их в виде готовых аналитических отчетов. MVoter сэкономит значительные финансовые и человеческие ресурсы вашей компании которые тратятся на мониторинг и анализ удовлетворенности (маркетинговые и социологические исследования) вашей Целевой Аудитории.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'six' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'six' ? 'none' : 'six' }) }>
                <div className={ styles.header }>Мы бы хотели чтобы наши клиенты оценивали не только в целом сервис, но и персонально сотрудников, отдельные помещения, и т.д. возможно ли это с MVoter?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'six' ? 'block' : 'none' }}>Да, конечно.</div>
                <span className={ styles.circle } />
              </li>
              <li className={ this.state.item === 'seven' ? styles.active : '' } onClick={ () => this.setState({ item: this.state.item === 'seven' ? 'none' : 'seven' }) }>
                <div className={ styles.header }>Можно ли адаптировать вашу систему под наш корпоративный дизайн?</div>
                <div className={ styles.description } style={{ display: this.state.item === 'seven' ? 'block' : 'none' }}>Легко. Система MVoter позволяет создать полностью адаптированную под ваш корпоративный дизайн систему обратной связи. Вы сможете создавать ваши собственные корпоративные QR информеры с вашим уникальным дизайном. Вы сможете адаптировать также и вашу административную панель контроля под требования вашего корпоративного дизайна.</div>
                <span className={ styles.circle } />
              </li>
            </ul>
          </div>
        </div>
      </Element>
    )
  }
}

export default Faq;
