const LOAD = 'GET_USERS_LOAD';
const LOAD_SUCCESS = 'GET_USERS_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_USERS_LOAD_FAIL';
const SET_CALL = 'SET_USERS';
const SET_CALL_SUCCESS = 'SET_USERS_SUCCESS';
const SET_CALL_FAIL = 'SET_USERS_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result.users,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getUsers() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get('/v1/users')
  };
}

export function setUsers(id, data) {
  return {
    types: [SET_CALL, SET_CALL_SUCCESS, SET_CALL_FAIL],
    promise: ({ client }) => client.post('/v1/users', {id, data})
  };
}
