const LOAD = 'GET_CALL_LOAD';
const LOAD_SUCCESS = 'GET_CALL_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_CALL_LOAD_FAIL';
const SET_CALL = 'SET_CALL';
const SET_CALL_SUCCESS = 'SET_CALL_SUCCESS';
const SET_CALL_FAIL = 'SET_CALL_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getCall(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/call?surveyId=${id}&limit=1-20&subId=web`)
  };
}

export function setCall(callerId, receivingId, surveyId, duration, status, createdAt, price) {
  return {
    types: [SET_CALL, SET_CALL_SUCCESS, SET_CALL_FAIL],
    promise: ({ client }) => client.post('/v1/call', { callerId, receivingId, surveyId, duration, status, createdAt, price })
  };
}
