import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Element } from 'react-scroll';
import { feedback } from 'redux/modules/auth';
import { required, email } from '../../utils/validation';

class Contact extends Component {
  static propTypes = {
    feedback: PropTypes.func
  };
  state = {
    successText: false,
    errorName: false,
    errorEmail: false,
    errorPhone: false,
    form: {
      firstname: '',
      lastname: '',
      company: '',
      position: '',
      email: '',
      phone: '',
      presentationChecked: false
    }
  };
  onChangeForm = (id, value) => {
    const newState = this.state;
    newState.form[id] = value;
    this.setState(newState);
  }
  btnSend = () => {
    if (this.state.form.firstname == '' || required(this.state.form.firstname)) {
      this.setState({ errorName: true });
      return false;
    }
    if (this.state.form.email == '' || email(this.state.form.email)) {
      this.setState({ errorEmail: true, errorName: false, errorPhone: false });
      return false;
    }
    if (this.state.form.phone == '' || required(this.state.form.phone)) {
      this.setState({ errorEmail: false, errorName: false, errorPhone: true });
      return false;
    }
    const newState = this.state;
    newState.successText = true;
    newState.errorName = false;
    newState.errorEmail = false;
    newState.errorPhone = false;
    this.props.feedback({ ...this.state.form, id: 'app:feedback' });
    this.setState(newState, () => setTimeout(() => this.setState({ successText: false }), 3000));
  }
  render() {
    const styles = require('./Contact.less');

    return (
      <Element name="link6" className={styles.section}>
        <div className={styles.inner}>
          <div className={styles.wrap}>
            <div className={styles.listImage}>
              <div className={styles.item}>
                <img src="./images/contact.svg" width="540" height="445" />
              </div>
              <div className={styles.item}>
                <div className={styles.form}>
                  <div className={styles.header}>Остались вопросы?</div>
                  <div className={styles.content}>
                    <form>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('firstname', e.target.value)}
                          type="text" placeholder="Имя *" className={this.state.errorName ? styles.error : ''} />
                      </div>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('lastname', e.target.value)}
                          type="text" placeholder="Фамилия" />
                      </div>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('company', e.target.value)}
                          type="text" placeholder="Компания" />
                      </div>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('position', e.target.value)}
                          type="text" placeholder="Должность" />
                      </div>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('email', e.target.value)}
                          type="email" placeholder="Email *" className={this.state.errorEmail ? styles.error : ''} />
                      </div>
                      <div className={styles.row}>
                        <input onChange={e => this.onChangeForm('phone', e.target.value)}
                          type="tel" placeholder="Телефон *" className={this.state.errorPhone ? styles.error : ''} />
                      </div>
                      <div className={styles.row}>
                        <label><input type="checkbox" onChange={e => this.onChangeForm('presentationChecked', e.target.checked)} />
                          Проведите для нас онлайн презентацию</label>
                        <p>Указывая свои данные вы даете согласие на обработку персональных данных,
                          в соответствии с политикой конфиденциальности</p>
                      </div>
                      {this.state.successText ?
                        <div className={styles.row}>
                          <p className={styles.successText}>Ваши данные успешно отправлены</p>
                        </div>
                        : null
                      }
                      <div className={styles.row}>
                        <span className={styles.button} onClick={this.btnSend}>Перезвоните мне</span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Element>
    )
  }
}

export default connect(
  () => ({}),
  { feedback }
)(Contact);

