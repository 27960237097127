import React, { Component } from 'react';

class Download extends Component {
  render() {
    const styles = require('./Download.less');
    return (
      <section className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>Дело будет двигаться, где бы вы ни были</h2>
            <h4>Управляйте системой обратной связи с вашей ЦА со своего смартфона <br />с помощью мобильного приложения «Mvoter Business»</h4>
            <div className={ styles.imageBlock }>
              <img src="./images/beach.svg" width="706" height="420" />
              <div className={ styles.appList }>
                <img src="./images/appstore.png" width="170" height="50" />
                <img src="./images/googleplay.png" width="170" height="50" />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Download;
