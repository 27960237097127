import React, { Component } from 'react';

class Template extends Component {
  render() {
    const styles = require('./Template.less');
    return (
      <section className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>Быстрый старт с помощью готовых шаблонов</h2>
            <div className={ styles.listImage }>
              <div className={ styles.item }>
                <img src="./images/template1.svg" width="540" height="445" />
                <p>Выберите из более 100 готовых анкет, подходящую для Вас</p>
              </div>
              <div className={ styles.item }>
                <img src="./images/template2.svg" width="540" height="443" />
                <p>Используйте наиболее понравившийся для вас дизайн из более 100 готовых информеров, постеров</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Template;
