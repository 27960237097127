const LOAD = 'GET_SURVEY_ANALYTICS_LOAD';
const LOAD_SUCCESS = 'GET_SURVEY_ANALYTICS_LOAD_SUCCESS';
const LOAD_FAIL = 'GET_SURVEY_ANALYTICS_LOAD_FAIL';
const SET_CHART = 'SET_CHART_LOAD';
const SET_CHART_SUCCESS = 'SET_CHART_SUCCESS';
const SET_CHART_FAIL = 'SET_CHART_FAIL'
const SET_SHARE_POINT = 'SET_SHARE_POINT_ANALYTICS_LOAD';
const SET_SHARE_POINT_SUCCESS = 'SET_SHARE_POINT_ANALYTICS_LOAD_SUCCESS';
const SET_SHARE_POINT_FAIL = 'SET_SHARE_POINT_ANALYTICS_LOAD_FAIL';
const GET_EXPORT = 'GET_EXPORT_ANALYTICS_LOAD';
const GET_EXPORT_SUCCESS = 'GET_EXPORT_ANALYTICS_LOAD_SUCCESS';
const GET_EXPORT_FAIL = 'GET_EXPORT_ANALYTICS_LOAD_FAIL';
const REMOVE_ANSWER = 'REMOVE_ANSWER_ANALYTICS_LOAD';
const REMOVE_ANSWER_SUCCESS = 'REMOVE_ANSWER_ANALYTICS_LOAD_SUCCESS';
const REMOVE_ANSWER_FAIL = 'REMOVE_ANSWER_ANALYTICS_LOAD_FAIL';
const SAVE_SHARE_REPORT = 'SAVE_SHARE_REPORT_LOAD';
const SAVE_SHARE_REPORT_SUCCESS = 'SAVE_SHARE_REPORT_SUCCESS';
const SAVE_SHARE_REPORT_FAIL = 'SAVE_SHARE_REPORT_FAIL';
const EXPORT_SHARE_REPORT = 'EXPORT_SHARE_REPORT_LOAD';
const EXPORT_SHARE_REPORT_SUCCESS = 'EXPORT_SHARE_REPORT_SUCCESS';
const EXPORT_SHARE_REPORT_FAIL = 'EXPORT_SHARE_REPORT_FAIL';

const initialState = {
  loaded: false
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        data: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getAnalytics(id, subId = 'none', subjectId = 'none', startDate = 'none', endDate = 'none') {
  if (subId === '') {
    subId = 'none';
  }
  if (subjectId === '') {
    subjectId = 'none';
  }
  if (startDate === '') {
    startDate = 'none';
  }
  if (endDate === '') {
    endDate = 'none';
  }
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics?id=${id}&subId=${subId}&subjectId=${subjectId}&startDate=${startDate}&endDate=${endDate}`)
  };
}

export function getAnalyticsOld(id, subId = 'none', subjectId = 'none', startDate = 'none', endDate = 'none') {
  if (subId === '') {
    subId = 'none';
  }
  if (subjectId === '') {
    subjectId = 'none';
  }
  if (startDate === '') {
    startDate = 'none';
  }
  if (endDate === '') {
    endDate = 'none';
  }
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics-old?id=${id}&subId=${subId}&subjectId=${subjectId}&startDate=${startDate}&endDate=${endDate}`)
  };
}

export function setChartType(id, chartType, question) {
  return {
    types: [SET_CHART, SET_CHART_SUCCESS, SET_CHART_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics/set-chart?id=${id}&chartType=${chartType}&question=${question}`)
  };
}

export function setSharePoint(id, active, code) {
  return {
    types: [SET_SHARE_POINT, SET_SHARE_POINT_SUCCESS, SET_SHARE_POINT_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics/publish?id=${id}&code=${code}&active=${active}`)
  };
}

export function getExport(id, code, subId = 'none', subjectId = 'none', startDate = 'none', endDate = 'none') {
  if (subId === '') {
    subId = 'none';
  }
  if (subjectId === '') {
    subjectId = 'none';
  }
  if (startDate === '') {
    startDate = 'none';
  }
  if (endDate === '') {
    endDate = 'none';
  }
  return {
    types: [GET_EXPORT, GET_EXPORT_SUCCESS, GET_EXPORT_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics/report?id=${id}&code=${code}&subId=${subId}&subjectId=${subjectId}&startDate=${startDate}&endDate=${endDate}`)
  };
}

export function setShareReport(id, data) {
  return {
    types: [SAVE_SHARE_REPORT, SAVE_SHARE_REPORT_SUCCESS, SAVE_SHARE_REPORT_FAIL],
    promise: ({ client }) => client.post('/v1/survey/analytics/share-save', { id, data })
  };
}

export function exportShareReport(id) {
  return {
    types: [EXPORT_SHARE_REPORT, EXPORT_SHARE_REPORT_SUCCESS, EXPORT_SHARE_REPORT_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics/share-export?id=${id}`)
  };
}

export function removeAnswer(id, code) {
  return {
    types: [REMOVE_ANSWER, REMOVE_ANSWER_SUCCESS, REMOVE_ANSWER_FAIL],
    promise: ({ client }) => client.get(`/v1/survey/analytics/remove?id=${id}&code=${code}`)
  };
}
