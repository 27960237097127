import React, { Component } from 'react';
import { Element } from 'react-scroll';

class Analytic extends Component {
  render() {
    const styles = require('./Analytic.less');
    return (
      <Element name="link3" className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>Получайте автоматически сформированные аналитические отчеты</h2>
            <h4>Экспортируйте статистику в удобном формате</h4>
            <ul className={ styles.exportList }>
              <li><img src="./images/analytic/word.svg" width="84" height="84" /></li>
              <li><img src="./images/analytic/pdf.svg" width="84" height="84" /></li>
              <li><img src="./images/analytic/excel.svg" width="84" height="84" /></li>
              <li><img src="./images/analytic/powerpoint.svg" width="84" height="84" /></li>
              <li><img src="./images/analytic/csv.svg" width="84" height="84" /></li>
              <li><img src="./images/analytic/xml.svg" width="84" height="84" /></li>
            </ul>
            <img src="./images/analytic.svg" width="720" height="510" />
          </div>
        </div>
      </Element>
    )
  }
}

export default Analytic;
