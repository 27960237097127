import React, { Component } from 'react';
import { Element } from 'react-scroll';

class About extends Component {
  render() {
    const styles = require('./About.less');
    return (
      <Element name="link2" className={ styles.section }>
        <div className={ styles.inner }>
          <div className={ styles.wrap }>
            <h2>О системе MVoter</h2>
            <ul className={ styles.aboutList }>
              <li>
                <div className={ styles.picture }><img src="./images/about/5.svg" width="84" height="84" /></div>
                <p>Ваши клиенты смогут здесь и сейчас оценивать ваши услуги или товары</p>
              </li>
              <li>
                <div className={ styles.picture }><img src="./images/about/2.svg" width="84" height="84" /></div>
                <p>Ваши клиенты смогут отправлять вам свои замечания и пожелания</p>
              </li>
              <li>
                <div className={ styles.picture }><img src="./images/about/1.svg" width="84" height="84" /></div>
                <p>Вы сможете моментально позвонить каждому клиенту отправившему негативный отзыв</p>
              </li>
              <li>
                <div className={ styles.picture }><img src="./images/about/6.svg" width="84" height="84" /></div>
                <p>Вы сможете видеть GPS координаты клиентов во время отзыва</p>
              </li>
              <li>
                <div className={ styles.picture }><img src="./images/about/3.svg" width="84" height="84" /></div>
                <p>Вы сможете выплачивать вознаграждения и бонусы вашим клиентам</p>
              </li>
              <li>
                <div className={ styles.picture }><img src="./images/about/4.svg" width="84" height="84" /></div>
                <p>Вы сможете получать готовые аналитические отчеты в режиме онлайн</p>
              </li>
            </ul>
          </div>
        </div>
      </Element>
    )
  }
}

export default About;
